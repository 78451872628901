<template>
	<div class="main-wrapper">
		<div class="d-flex justify-content-between ">
			<h4 class="mb-4">
				User info
			</h4>
			<div class="typo-body-2 color-black-45">
				<span>User group: </span>
				<strong>{{ profile.data.group }}</strong>
			</div>
		</div>
		<BaseLoading
			v-if="profile.isLoading"
			is-fullpage
		/>
		<CForm
			v-else
			@submit.prevent="submit"
		>
			<CRow align-horizontal="center">
				<CCol lg="12">
					<CInput
						v-model.trim="$v.username.$model"
						disabled
						label="Username"
					/>
				</CCol>
			</CRow>
			<CRow align-horizontal="center">
				<CCol lg="12">
					<div v-if="!isAllowChangePassword">
						<div class="label">
							Password
						</div>
						<CButton
							color="tertiary"
							class="mb-4"
							@click="handleAllowChangePassword"
						>
							Change password
						</CButton>
					</div>
					<CRow v-else>
						<CCol>
							<CInput
								v-model.trim="$v.oldPassword.$model"
								:is-valid="!$v.oldPassword.$error && null"
								:invalid-feedback="errorOldPasswordMessage"
								:description="getOldPasswordDescription"
								type="password"
								label="Old password*"
							/>
						</CCol>
						<CCol>
							<CInput
								v-if="isAllowChangePassword"
								v-model.trim="$v.newPassword.$model"
								:is-valid="!$v.newPassword.$error && null"
								:invalid-feedback="errorNewPasswordMessage"
								type="password"
								label="New password"
							/>
						</CCol>
					</CRow>
				</CCol>
			</CRow>
			<CRow align-horizontal="center">
				<CCol lg="6">
					<CInput
						v-model.trim="$v.firstName.$model"
						:is-valid="!$v.firstName.$error && null"
						:invalid-feedback="getValidateFirstNameMessage"
						label="First name*"
					/>
				</CCol>
				<CCol lg="6">
					<CInput
						v-model.trim="$v.lastName.$model"
						:is-valid="!$v.lastName.$error && null"
						:invalid-feedback="getValidateLastNameMessage"
						label="Last name"
					/>
				</CCol>
			</CRow>
			<CRow align-horizontal="center">
				<CCol lg="12">
					<CInput
						v-model.trim="$v.email.$model"
						:is-valid="!$v.email.$error && null"
						:invalid-feedback="getValidateEmailMessage"
						label="Email address"
					/>
				</CCol>
			</CRow>
			<CRow align-horizontal="center">
				<CCol lg="12">
					<CInput
						v-model.trim="$v.phone.$model"
						:is-valid="!$v.phone.$error && null"
						:invalid-feedback="getValidatePhoneMessage"
						label="Phone"
					/>
				</CCol>
			</CRow>
			<CRow align-horizontal="center">
				<CCol lg="12">
					<CInput
						:value="store"
						readonly
						label="Store ID"
					/>
				</CCol>
			</CRow>
			<CRow align-horizontal="center" class="mb-5">
				<CCol lg="12">
					<CTextarea
						v-model.trim="$v.note.$model"
						:is-valid="!$v.note.$error && null"
						:invalid-feedback="getValidateNoteMessage"
						label="Note"
					/>
				</CCol>
			</CRow>
			<BaseActionPanelStickyFooter
				:disabled-confirm="profile.isUpdating"
				is-edit
				@onConfirm="submit"
				@onCancel="$router.go(-1)"
			/>
		</CForm>
	</div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { required, minLength, email, maxLength } from 'vuelidate/lib/validators';
import { validateHandler } from '../assets/js/selectors/global/validation';
import { scrollToTop } from '../assets/js/helpers';

export default {
	validations() {
		const schema = {
			username: {
				required,
				minLength: minLength(4),
				maxLength: maxLength(255),
			},
			firstName: {
				required,
				maxLength: maxLength(35),

			},
			lastName: {
				maxLength: maxLength(35),
			},
			email: {
				format(value) {
					return email(value);
				},
				maxLength: maxLength(255),
			},
			phone: {
				maxLength: maxLength(255),
			},
			note: {
				maxLength: maxLength(150),
			},
		};

		if (this.isAllowChangePassword) {
			schema.oldPassword = {
				required,
				minLength: minLength(8),
				maxLength: maxLength(255),
			};

			schema.newPassword = {
				required,
				minLength: minLength(8),
				maxLength: maxLength(255),
				notSameOldPassword(value) {
					return value !== this.oldPassword;
				},
			};
		}

		return schema;
	},
	data() {
		return {
			username: null,
			oldPassword: null,
			newPassword: null,
			firstName: null,
			lastName: null,
			email: null,
			phone: null,
			store: null,
			note: null,
			status: null,
			isAllowChangePassword: false,
		};
	},
	computed: {
		...mapState('profile', {
			profile: 'profile',
		}),
		errorOldPasswordMessage() {
			if (!this.$v.oldPassword.required) {
				return this.$t('page.users.create.validationMessage.password.required');
			}

			if (!this.$v.oldPassword.minLength || !this.$v.oldPassword.maxLength) {
				return this.$t('page.users.create.validationMessage.password.lengthLimit');
			}

			return null;
		},
		errorNewPasswordMessage() {
			if (!this.$v.newPassword.required) {
				return this.$t('page.users.create.validationMessage.password.required');
			}

			if (!this.$v.newPassword.minLength || !this.$v.newPassword.maxLength) {
				return this.$t('page.users.create.validationMessage.password.lengthLimit');
			}

			if (!this.$v.newPassword.notSameOldPassword) {
				return this.$t('page.users.create.validationMessage.password.notSameOldPassword');
			}

			return null;
		},
		getValidateEmailMessage() {
			const field = this.$v.email;
			return validateHandler(field, {
				existMessage: this.$t('page.users.create.validationMessage.email.exists'),
				lengthLimitMessage: this.$t('page.users.create.validationMessage.email.lengthLimit'),
				invalidFormatMessage: this.$t('page.users.create.validationMessage.email.invalidFormat'),
			});
		},
		getValidatePhoneMessage() {
			const field = this.$v.email;
			return validateHandler(field, {
				lengthLimitMessage: this.$t('page.users.create.validationMessage.phone.lengthLimit'),
			});
		},
		getValidateFirstNameMessage() {
			const field = this.$v.firstName;

			return validateHandler(field, {
				requiredMessage: this.$t('page.users.create.validationMessage.firstName.required'),
				lengthLimitMessage: this.$t('page.users.create.validationMessage.firstName.lengthLimit'),
			});
		},
		getValidateLastNameMessage() {
			const field = this.$v.lastName;
			return validateHandler(field, {
				lengthLimitMessage: this.$t('page.users.create.validationMessage.lastName.lengthLimit'),
			});
		},
		getValidateNoteMessage() {
			const field = this.$v.note;
			return validateHandler(field, {
				lengthLimitMessage: this.$t('page.users.create.validationMessage.note.lengthLimit'),
			});
		},
		getOldPasswordDescription() {
			const field = this.$v.oldPassword;

			if (field.$error) {
				return '';
			}
			return this.$t('page.users.create.description.password');
		},
	},
	created() {
		this.username = this.profile.data.username;
		this.email = this.profile.data.email;
		this.phone = this.profile.data.phoneNumber;
		this.firstName = this.profile.data.firstName;
		this.lastName = this.profile.data.lastName;
		this.status = this.profile.data.status;
		this.store = this.profile.data.storeId ? `${this.profile.data.storeItecId} - ${this.profile.data.storeName}` : null;
		this.note = this.profile.data.note;
	},
	methods: {
		...mapActions({
			updateProfile: 'profile/updateProfile',
		}),

		async submit() {
			this.$v.$touch();
			if (this.$v.$invalid) {
				scrollToTop();
				return;
			}

			const params = {
				email: this.email,
				password: this.newPassword,
				old_password: this.oldPassword,
				first_name: this.firstName,
				last_name: this.lastName,
				note: this.note,
				phone_number: this.phone,
			};

			try {
				await this.updateProfile(params);

				this.isAllowChangePassword = false;
			} catch {
				// Do nothing
			}
		},
		handleAllowChangePassword() {
			this.isAllowChangePassword = true;
		},
	},
};
</script>
