export const validateHandler = (
	fieldRule = {},
	fieldMessage = {
		existMessage: '',
		requiredMessage: '',
		lengthLimitMessage: '',
		invalidFormatMessage: '',
		alphaNumnericMessage: '',
	},
) => {
	const isDuplicate = ('isDuplicate' in fieldRule) && !fieldRule.isDuplicate;
	const isRequired = ('required' in fieldRule) && !fieldRule.required;
	const isLimitLength = (('maxLength' in fieldRule) ||
		('maxLength' in fieldRule)) &&
		(!fieldRule.maxLength || !fieldRule.minLength);

	const isFormat = ('format' in fieldRule) && !fieldRule.format;
	const isAlphanumeric = ('alphanumeric' in fieldRule) && !fieldRule.alphanumeric;

	if (isFormat) {
		return fieldMessage.invalidFormatMessage;
	} else if (isDuplicate) {
		return fieldMessage.existMessage;
	} else if (isRequired) {
		return fieldMessage.requiredMessage;
	} else if (isLimitLength) {
		return fieldMessage.lengthLimitMessage;
	} else if (isAlphanumeric) {
		return fieldMessage.alphaNumnericMessage;
	}

	return '';
};

export const checkIfValid = (field) => {
	if (!field.$dirty) {
		return null;
	}

	if (!('required' in field) && !field.$error) {
		return null;
	}

	return !(field.$invalid || field.$error) && null;
};
